
import { Vue, Component, Prop } from 'vue-property-decorator'

/*
 * Known keys:
 * Confirmed Reservation, Buffer Time, Draft, Blocked, Schedule Conflict,
 * Confirmed Assignment, Draft Assignment, Unassigned
 */

@Component
export default class CUTimelineLegend extends Vue {
  @Prop({ required: true, default: [] }) keys: string[]

  get formattedKeys(): { label: string; class: string }[] {
    return this.keys.map((key) => {
      switch (key) {
        case 'Confirmed Reservation':
          return { label: key, class: 'background-secondary' }
        case 'Buffer Time':
          return { label: key, class: 'border-1 border-solid border-bg-gray-5' }
        case 'Draft':
          return { label: key, class: 'background-bg-gray-5' }
        case 'Blocked':
          return { label: key, class: 'blocked-box' }
        case 'Schedule Conflict':
          return {
            label: key,
            class: 'border-1 border-solid border-additional-yellow',
          }
        case 'Confirmed Assignment':
          return { label: key, class: 'background-secondary' }
        case 'Draft Assignment':
          return { label: key, class: 'background-bg-gray-5' }
        case 'Unassigned':
          return {
            label: key,
            class: 'border-1 border-solid border-primary background-primary-10',
          }
        default:
          return { label: key, class: '' }
      }
    })
  }
}
