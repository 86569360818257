
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AssignmentsVehicleBlock } from '@/models/dto/AssignmentsGrid'
import sidebar from '@/store/modules/sidebar'
import VehicleSidebar from './VehicleSidebar.vue'
import assignment from '@/store/modules/assignment'
import { EventBus } from '@/utils/eventBus'

@Component
export default class AssignmentsVehicleCard extends Vue {
  @Prop({ required: true }) vehicle: AssignmentsVehicleBlock

  isCollapsed = false

  get isSelected(): boolean {
    return assignment.getSelectedBlock?.vehicleId === this.vehicle.vehicleId
  }

  get isDummyVehicle(): boolean {
    return this.vehicle.dummy
  }

  get colors(): { text: string; icon: string } {
    const isDark = !assignment.getSelectedReservation || this.isSelected
    return {
      text: isDark ? 'black' : 'gray-text-mid',
      icon: isDark ? 'gray-icon-dark' : 'gray-icon-light',
    }
  }

  // Delay showing the row's content when expanding
  @Watch('vehicle.collapsed', { deep: true, immediate: true })
  handleVehicleUpdate(newVal: boolean, oldVal: boolean): void {
    if (oldVal === true && newVal === false) {
      setTimeout(() => {
        this.isCollapsed = false
      }, 300)
    } else {
      this.isCollapsed = newVal
    }
  }

  handleVehicleClick(): void {
    sidebar.popAllAndPush({
      component: VehicleSidebar,
      width: 672,
      props: {
        activeVehicleDetailId: this.vehicle.vehicleId,
        isModeEdit: true,
        title: this.vehicle.vehicleName,
      },
    })
  }

  handleVehicleRefresh(): void {
    sidebar.pop()
    assignment.clearVehiclesAndDrivers()
    assignment.refresh()
  }

  mounted(): void {
    EventBus.$on('vehicles:refresh', this.handleVehicleRefresh)
  }

  beforeDestroy(): void {
    EventBus.$off('vehicles:refresh', this.handleVehicleRefresh)
  }
}
