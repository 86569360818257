
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  AssignmentsDriverBlock,
  AssignmentsRowBlock,
  TimelineScaleOptions,
} from '@/models/dto/AssignmentsGrid'
import { getWidthOfTextInFont } from '@/utils/string'
import assignment from '@/store/modules/assignment'
import sidebar from '@/store/modules/sidebar'
import AssignmentsSidebar from './AssignmentsSidebar.vue'
import colors from '@/scss/_colors-export.scss'

@Component
export default class AssignmentsDriverCalendarRow extends Vue {
  @Prop({ required: false }) driver: AssignmentsDriverBlock

  assignment = assignment

  isDragging = null
  // isDraggedOver = false
  canvas = null
  topOffset = 0
  initialMouseY = 0

  get rowHeight(): number {
    return Math.max(this.driver.top + 1, 1) * 30 + 2
  }

  get selected(): { resId: number; userId: number } {
    return {
      resId: assignment.getSelectedReservation?.reservationId,
      userId: assignment.getSelectedDriverId,
    }
  }

  get bufferTimeWidth(): number {
    switch (assignment.getScale) {
      case TimelineScaleOptions.HALFDAY:
        return (2400 / 24 / 60) * 60
      case TimelineScaleOptions.DAY:
        return (1200 / 24 / 60) * 60
      case TimelineScaleOptions.HALFWEEK:
        return (400 / 24 / 60) * 60
      case TimelineScaleOptions.WEEK:
        return (200 / 24 / 60) * 60
      default:
        return 3
    }
  }

  get assigningCoords(): { start: number; end: number } {
    if (!assignment.getIsAssigning) {
      return null
    }
    return {
      start: assignment.getSelectedReservation.start,
      end: assignment.getSelectedReservation.end,
    }
  }

  getTopOffsetFromBlock(block: AssignmentsRowBlock): string {
    let offset = block.top * 30
    if (!this.isSelected(block)) {
      offset += 2
    }
    if (this.isDragging === block.reservationId) {
      offset += this.topOffset - 64
    }

    return `${offset}px`
  }

  getLeftOffsetFromBlock(block: AssignmentsRowBlock): string {
    let offset = block.start
    if (this.isSelected(block)) {
      offset -= 1
    }
    if (this.isDragging === block.reservationId) {
      offset -= 64
    }

    return `${offset}px`
  }

  blockColor(block: AssignmentsRowBlock): string {
    const base = block.isConfirmed ? colors['secondary'] : colors['bg-gray-5']
    return !this.selected.resId || this.selected.resId === block.reservationId
      ? base
      : `${base}60`
  }

  isSelected(block: AssignmentsRowBlock): boolean {
    return (
      this.selected.resId === block.reservationId &&
      this.selected.userId === this.driver.userId
    )
  }

  shouldShowAssignmentIndex(block: AssignmentsRowBlock): boolean {
    const validAssignments =
      assignment.getSelectedReservation?.reservationId === block.reservationId
        ? assignment.getSelectedAssignments
            ?.flatMap((a) => a.drivers)
            .filter((d) => !!d.id)
        : []
    const isBlockWideEnough =
      getWidthOfTextInFont(block.managedId, '12px', '700', this.canvas) <
      block.end - block.start - 30
    return (
      (block.driverDisplayAssignmentIndex || validAssignments.length > 1) &&
      isBlockWideEnough
    )
  }

  getBlockAssignmentIndex(block: AssignmentsRowBlock): number {
    const matchingSelectedAssignmentIdx =
      assignment.getSelectedReservation?.reservationId === block.reservationId
        ? assignment.getSelectedAssignments
            ?.flatMap((a) => a.drivers)
            .findIndex((d) => d.id === block.userId)
        : -1
    if (matchingSelectedAssignmentIdx !== -1) {
      return matchingSelectedAssignmentIdx + 1
    }
    return block.driverDisplayAssignmentIndex
  }

  shouldShowTooltip(block: AssignmentsRowBlock): boolean {
    return !this.isDragging && !this.isSelected(block)
  }

  handleSelectBlock(block: AssignmentsRowBlock): void {
    assignment.setSelectedIds({
      reservationId: block.reservationId,
      vehicleId: block.vehicleId,
      vehicleIdx: (block.vehicleAssignmentIndex || 1) - 1,
      driverId: this.driver.userId,
      driverIdx: block.driverAssignmentIndex || 0,
      isDriver: true,
    })

    sidebar.popAllAndPush({
      component: AssignmentsSidebar,
      persist: true,
      width: 512,
    })

    if (this.$router.currentRoute.hash !== `#${block.reservationId}`) {
      this.$router.replace({ hash: `#${block.reservationId}` })
    }
  }

  handleMouseDown(block: AssignmentsRowBlock, e: any): void {
    e.preventDefault()
    this.isDragging = block.reservationId
    this.initialMouseY = e.clientY
  }

  handleDrag(block: AssignmentsRowBlock, e: any): void {
    if (this.isDragging && this.isSelected(block)) {
      const deltaY = e.clientY - this.initialMouseY
      this.topOffset = deltaY
    }
  }

  handleMouseUp(block: AssignmentsRowBlock): void {
    assignment.dragDriverBlockTo({ dragDistance: this.topOffset, block })
    this.isDragging = null
    this.topOffset = 0
  }
}
