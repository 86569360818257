var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.vehicle.isVehicleType)?_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"h-32 background-additional-blue-2 border-radius-bottom-left-8"},[_c('div',{staticClass:"h-full d-flex justify-space-between align-center padding-x-2 cursor-pointer border-y-0 border-x-1 border-solid border-gray-border-light",on:{"click":function($event){return _vm.$emit('collapse', _vm.vehicle.vehicleType.id)}}},[_c('p',{staticClass:"font-bold text-additional-blue"},[_vm._v(" "+_vm._s(_vm.vehicle.vehicleType.label)+" ")]),_c('CUIcon',{staticClass:"transition-all transition-duration-300",class:{ 'rotate-n180': !_vm.vehicle.collapsed },attrs:{"width":"14px","height":"14px","color":"additional-blue","aria-label":_vm.vehicle.collapsed ? 'Expand' : 'Collapse'}},[_vm._v(" keyboard_arrow_down ")])],1)])]):_c('div',{staticClass:"h-full d-inline-table cursor-pointer",class:{
    'bg-gradient': _vm.isSelected,
    'background-white': !_vm.isSelected,
    'border-white': _vm.vehicle.collapsed,
    'border-y-0 border-x-1 border-solid border-gray-border-light':
      !_vm.vehicle.collapsed,
  },style:({
    minHeight:
      !_vm.vehicle.collapsed && _vm.vehicle.hasConflict ? 'calc(100% + 1px)' : '',
  }),on:{"click":_vm.handleVehicleClick}},[_c('div',{staticClass:"h-full d-flex align-center",class:{
      'border-b-1 border-x-0 border-t-0 border-solid border-gray-border-light':
        !_vm.vehicle.collapsed && !_vm.vehicle.hasConflict,
      'border-y-1 border-x-0 border-solid border-additional-yellow':
        !_vm.vehicle.collapsed && _vm.vehicle.hasConflict,
    },style:({
      marginTop: !_vm.vehicle.collapsed && _vm.vehicle.hasConflict ? '-1px' : '',
    })},[_c('div',{staticClass:"d-flex align-center flex-wrap padding-x-2 transition-property-max-height transition-duration-300",style:({
        maxHeight: _vm.vehicle.collapsed ? '0px' : '2000px',
        gap: '2px',
      })},[(!_vm.isCollapsed)?[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1",attrs:{"width":"14px","color":_vm.colors.icon}},[_vm._v(" group ")]),_c('p',{staticClass:"font-12 font-bold w-24",class:`text-${_vm.colors.icon}`},[_vm._v(" "+_vm._s(_vm.vehicle.passengerCapacity || '--')+" ")])],1),_c('p',{staticClass:"font-12 font-bold white-space-nowrap overflow-x-hidden text-overflow-ellipsis",class:`text-${_vm.colors.text} w-${_vm.isDummyVehicle ? '76' : '92'}`},[_vm._v(" "+_vm._s(_vm.vehicle.vehicleName)+" ")]),(_vm.vehicle.hasConflict)?_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1",attrs:{"width":"11px","color":"additional-yellow"}},[_vm._v(" warning ")]),_c('p',{staticClass:"font-12 font-bold text-additional-yellow white-space-nowrap"},[_vm._v(" Potential conflict ")])],1):_vm._e()]:_vm._e()],2),_c('CUTooltip',{attrs:{"tooltip-text":"<b>Vehicle not included in availability calculation</b>","max-width":"300px","top":""}},[(_vm.isDummyVehicle && !_vm.isCollapsed)?_c('CUIcon',{staticClass:"margin-r-2",class:{
          'opacity-1': !_vm.vehicle.collapsed,
          'opacity-0': _vm.vehicle.collapsed,
        },style:({ marginBottom: '-6px' }),attrs:{"width":"16px","aria-label":"Dummy Vehicle","color":"additional-yellow"}},[_vm._v(" ad_off ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }