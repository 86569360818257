
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TimelineScaleOptions } from '@/models/dto/AssignmentsGrid'
import { Dayjs } from 'dayjs'

@Component
export default class CUTimelineCalendarTimeline extends Vue {
  @Prop({ required: true }) scale: string
  @Prop({ required: true }) startDate: Dayjs
  @Prop({ required: true }) selected: { start: number; end: number }

  get showSelected(): boolean {
    return (
      !!(this.selected?.start || this.selected?.start === 0) &&
      !!this.selected?.end
    )
  }

  get is12Hours(): boolean {
    return this.scale === TimelineScaleOptions.HALFDAY
  }

  get is24Hours(): boolean {
    return this.scale === TimelineScaleOptions.DAY
  }

  get is3days(): boolean {
    return this.scale === TimelineScaleOptions.HALFWEEK
  }

  get is7days(): boolean {
    return this.scale === TimelineScaleOptions.WEEK
  }

  get options(): { repeat: number; width: number } {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return { repeat: 2, width: 2400 }
      case TimelineScaleOptions.DAY:
        return { repeat: 4, width: 1200 }
      case TimelineScaleOptions.HALFWEEK:
        return { repeat: 12, width: 400 }
      case TimelineScaleOptions.WEEK:
        return { repeat: 24, width: 200 }
      default:
        return { repeat: 4, width: 1200 }
    }
  }

  get bufferTimeWidth(): number {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return (2400 / 24 / 60) * 60
      case TimelineScaleOptions.DAY:
        return (1200 / 24 / 60) * 60
      case TimelineScaleOptions.HALFWEEK:
        return (400 / 24 / 60) * 60
      case TimelineScaleOptions.WEEK:
        return (200 / 24 / 60) * 60
      default:
        return 3
    }
  }

  getDateString(index: number): string {
    if (!this.startDate) {
      return ''
    }
    return this.startDate.add(index, 'days').format('ddd, MM/DD')
  }
}
