var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transition-all transition-duration-300 position-relative",class:{
    'background-additional-blue-2': !_vm.driver.userId,
    'bg-selected-primary': _vm.selected.userId === _vm.driver.userId,
    'border-white': _vm.driver.userId && _vm.driver.collapsed,
    'border-b-1 border-x-0 border-t-0 border-solid border-gray-border-light':
      _vm.driver.userId && !_vm.driver.collapsed && !_vm.driver.hasConflict,
    'border-b-1 border-x-0 border-t-1 border-solid border-additional-yellow':
      !_vm.driver.collapsed && _vm.driver.hasConflict,
  },style:({
    marginTop: !_vm.driver.collapsed && _vm.driver.hasConflict ? '-1px' : '0',
    height: _vm.driver.collapsed && _vm.driver.userId ? '0px' : `${_vm.rowHeight}px`,
  })},[(_vm.driver.userId && !_vm.driver.collapsed)?_c('div',{staticClass:"position-relative"},[_vm._l((_vm.driver.posRowBlocks),function(block,idx){return _c('div',{key:`block-map-${block.reservationId}-${idx}`,ref:`block-map-${block.reservationId}-${block.vehicleId}`,refInFor:true,staticClass:"position-relative",on:{"click":function($event){return _vm.handleSelectBlock(block)}}},[(block.conflict)?_c('div',{staticClass:"position-absolute background-additional-yellow-2 z-1",style:({
          left: `${block.conflict.start}px`,
          top: '0',
          height: `${_vm.rowHeight - 2}px`,
          width: `${
            block.conflict.end + _vm.bufferTimeWidth - block.conflict.start
          }px`,
        })}):_vm._e(),_c('div',{staticClass:"position-absolute border-radius",class:{
          'padding-a-16 z-7': _vm.isDragging === block.reservationId,
          'z-6': _vm.isSelected(block),
          'z-3': !_vm.isSelected(block),
        },style:({
          left: _vm.getLeftOffsetFromBlock(block),
          top: _vm.getTopOffsetFromBlock(block),
        }),on:{"mousedown":function($event){return _vm.handleMouseDown(block, $event)},"mouseup":function($event){return _vm.handleMouseUp(block)},"mousemove":function($event){return _vm.handleDrag(block, $event)}}},[_c('div',{staticClass:"d-flex",class:{
            'border-2 border-solid border-primary border-radius-6':
              _vm.isSelected(block) && block.start !== 0,
            'border-y-2 border-r-2 border-l-0 border-solid border-primary border-radius-top-right-6 border-radius-bottom-right-6':
              _vm.isSelected(block) && block.start === 0,
          }},[_c('CUTooltip',{attrs:{"tooltip-text":`<b>${block.managedId}</b>`,"warning":!!block.conflict,"max-width":"300px","top":""}},[_c('div',{staticClass:"d-flex align-center justify-center w-full cursor-pointer padding-x-1 transition-background transition-duration-300",class:{
                'border-radius-top-left-4 border-radius-bottom-left-4':
                  block.start !== 0,
              },style:({
                width: `${block.end - block.start}px`,
                height: '26px',
                backgroundColor: _vm.blockColor(block),
              }),attrs:{"data-testid":`assignment-block-${block.managedId}`}},[(block.conflict)?_c('div',{staticClass:"position-relative d-flex align-center justify-center flex-shrink-0 margin-l-1 rounded-pill background-white border-1 border-solid border-white",style:({
                  height: '20px',
                  width: '20px',
                })},[_c('CUIcon',{attrs:{"color":"red-error","width":"16px"}},[_vm._v("warning")])],1):_vm._e(),_c('p',{staticClass:"font-12 font-bold text-white text-overflow-ellipsis overflow-hidden white-space-nowrap",class:{ 'margin-l-1': block.conflict }},[_vm._v(" "+_vm._s(block.managedId)+" ")]),(_vm.shouldShowAssignmentIndex(block))?_c('div',{staticClass:"d-flex align-center justify-center flex-shrink-0 h-20 w-20 margin-l-1 border-1 border-solid border-white-60 rounded-pill"},[_c('p',{staticClass:"font-12 font-bold text-white-60"},[_vm._v(" "+_vm._s(_vm.getBlockAssignmentIndex(block))+" ")])]):_vm._e()])]),_c('div',{staticClass:"background-bg-gray-1 border-1 border-solid border-radius-top-right-4 border-radius-bottom-right-4",style:({
              height: '26px',
              width: `${_vm.bufferTimeWidth}px`,
              borderColor: _vm.blockColor(block),
            })})],1)])])}),(_vm.assigningCoords)?_c('div',{staticClass:"d-flex align-center justify-center position-absolute border-radius-4 cursor-pointer opacity-0 hover:opacity-100 background-gray-bg transition-opacity transition-duration-300",style:({
        left: `${_vm.assigningCoords.start}px`,
        top: '0',
        height: `${_vm.rowHeight - 2}px`,
        width: `${
          _vm.assigningCoords.end + _vm.bufferTimeWidth - _vm.assigningCoords.start
        }px`,
      }),on:{"click":function($event){return _vm.assignment.setAssignedFromCalendar(_vm.driver.vehicleId)}}},[_c('CUIcon',{staticClass:"cursor-pointer",attrs:{"width":"21px","color":"primary","decorative":""}},[_vm._v(" event_upcoming ")])],1):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }