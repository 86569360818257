var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.driver.userId)?_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"h-32 background-additional-blue-2 border-radius-bottom-left-8"},[_c('div',{staticClass:"h-full d-flex justify-space-between align-center padding-x-2 border-y-0 border-x-1 border-solid border-gray-border-light"},[_c('p',{staticClass:"font-bold text-additional-blue"},[_vm._v("Drivers")]),_c('div',{staticClass:"d-flex align-center",style:({ gap: '4px' })},[_c('p',{staticClass:"font-bold text-additional-blue"},[_vm._v("A")]),_c('CUIcon',{attrs:{"color":"additional-blue","width":"14px"}},[_vm._v("arrow_right")]),_c('p',{staticClass:"font-bold text-additional-blue"},[_vm._v("Z")])],1)])])]):_c('div',{staticClass:"h-full d-inline-table cursor-pointer",class:{
    'bg-gradient': _vm.isSelected,
    'background-white': !_vm.isSelected,
    'border-white': _vm.driver.collapsed,
    'border-y-0 border-x-1 border-solid border-gray-border-light':
      !_vm.driver.collapsed,
  },style:({
    minHeight:
      !_vm.driver.collapsed && _vm.driver.hasConflict ? 'calc(100% + 1px)' : '',
  }),on:{"click":_vm.handleDriverClick}},[_c('div',{staticClass:"h-full d-flex align-center",class:{
      'border-b-1 border-x-0 border-t-0 border-solid border-gray-border-light':
        !_vm.driver.collapsed && !_vm.driver.hasConflict,
      'border-y-1 border-x-0 border-solid border-additional-yellow':
        !_vm.driver.collapsed && _vm.driver.hasConflict,
    },style:({
      marginTop: !_vm.driver.collapsed && _vm.driver.hasConflict ? '-1px' : '',
    })},[_c('div',{staticClass:"d-flex align-center flex-wrap padding-x-2 transition-property-max-height transition-duration-300",style:({
        maxHeight: _vm.driver.collapsed ? '0px' : '2000px',
        gap: '2px',
      })},[(!_vm.isCollapsed)?[_c('p',{staticClass:"font-12 font-bold white-space-nowrap overflow-x-hidden text-overflow-ellipsis",class:`text-${_vm.colors.text}`,style:({ width: '142px' })},[_vm._v(" "+_vm._s(_vm.driver.driverName)+" ")]),(_vm.driver.hasConflict)?_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1",attrs:{"width":"11px","color":"additional-yellow"}},[_vm._v(" warning ")]),_c('p',{staticClass:"font-12 font-bold text-additional-yellow white-space-nowrap"},[_vm._v(" Potential conflict ")])],1):_vm._e()]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }