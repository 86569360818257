
import { Vue, Component } from 'vue-property-decorator'
import dayjs from 'dayjs'
import assignment from '@/store/modules/assignment'
import assignmentClient from '@/services/assignments'
import HoldUpModal from '@/components/HoldUpModal.vue'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import AssignmentsUnassignedSidebar from '@/components/AssignmentsUnassignedSidebar.vue'

@Component({ components: { HoldUpModal } })
export default class AssignmentsHeader extends Vue {
  assignment = assignment

  showConfirmAllModal = false

  get allAssignmentsConfirmed(): boolean {
    return assignment.numDraftAssignments === 0
  }

  get numDrafts(): number {
    return assignment.getNumDraftAssignments || null
  }

  get startDate(): string {
    return assignment.getCurrentDate.toISOString()
  }

  get pickup(): { date: string; time: string; loc: string } {
    const trip = assignment.getSelectedTrip
    if (!trip) {
      return { date: null, time: null, loc: null }
    }
    const start = trip?.stops?.[0]?.pickupDatetime
    const tz = trip?.stops?.[0]?.address?.timeZone
    const date = dayjs(start).tz(tz)
    return {
      date: date.format('ddd, MM/DD/YY'),
      time: date.format('hh:mma z'),
      loc: `${trip.stops[0].address?.city}, ${trip.stops[0].address?.state}`,
    }
  }

  get dropoff(): { date: string; time: string; loc: string } {
    const trip = assignment.getSelectedTrip
    if (!trip) {
      return { date: null, time: null, loc: null }
    }
    const stop = trip.stops[trip.stops.length - 1]
    const end = stop?.dropoffDatetime || stop?.pickupDatetime
    const tz = stop?.address?.timeZone
    const date = dayjs(end).tz(tz)
    return {
      date: date.format('ddd, MM/DD/YY'),
      time: date.format('hh:mma z'),
      loc: `${stop.address.city}, ${stop.address.state}`,
    }
  }

  handleClickMainView(): void {
    assignment.clearSelected()
    sidebar.close()

    this.$router.replace({ hash: '' })
  }

  handleUpdateDate(date: string): void {
    assignment.setCurrentDate(dayjs(date))
    assignment.refresh()
    EventBus.$emit('reset-scroll', {
      offset: 0,
      id: null,
    })
  }

  handleIncrementDate(): void {
    assignment.incrementDate()
  }

  handleDecrementDate(): void {
    assignment.decrementDate()
  }

  handleUpdateScale(scale: string): void {
    assignment.setScale(scale)
    assignment.refreshLocalData()
  }

  async handleConfirmAll(): Promise<void> {
    try {
      await assignmentClient.confirmAll()
      EventBus.$emit('snackbar:success', 'Assignments confirmed successfully!')
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to confirm all assignments.')
    }
  }

  handleReviewDrafts(): void {
    return
  }

  handleViewUnassigned(): void {
    sidebar.popAllAndPush({
      component: AssignmentsUnassignedSidebar,
      width: 1200,
      persist: true,
    })
  }
}
