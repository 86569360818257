
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AssignmentsDriverBlock } from '@/models/dto/AssignmentsGrid'
import sidebar from '@/store/modules/sidebar'
import assignment from '@/store/modules/assignment'
import { EventBus } from '@/utils/eventBus'
import EditUserSidebar from './EditUserSidebar.vue'
import userClient from '@/services/user'

@Component
export default class AssignmentsDriverCard extends Vue {
  @Prop({ required: true }) driver: AssignmentsDriverBlock

  isCollapsed = false

  get isSelected(): boolean {
    return assignment.getSelectedDriverId === this.driver.userId
  }

  get colors(): { text: string; icon: string } {
    const isDark = !assignment.getSelectedReservation || this.isSelected
    return {
      text: isDark ? 'black' : 'gray-text-mid',
      icon: isDark ? 'gray-icon-dark' : 'gray-icon-light',
    }
  }

  async handleDriverClick(): Promise<void> {
    let user = null
    try {
      const res = await userClient.byId(this.driver.userId)
      user = res.data.user
    } catch (e) {
      console.error(e)
      return
    }
    sidebar.popAllAndPush({
      component: EditUserSidebar,
      props: { user },
      width: 675,
    })
  }

  handleDriverRefresh(): void {
    sidebar.pop()
    assignment.clearVehiclesAndDrivers()
    assignment.refresh()
  }

  mounted(): void {
    EventBus.$on('drivers:refresh', this.handleDriverRefresh)
  }

  beforeDestroy(): void {
    EventBus.$off('drivers:refresh', this.handleDriverRefresh)
  }
}
