
import assignment from '@/store/modules/assignment'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component } from 'vue-property-decorator'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import reservationClient from '@/services/reservation'
import { Reservation } from '@/models/dto'
import { DataTableColumn } from '@/models/DataTableColumn'
import CUDataTableLink from './CUDataTableLink.vue'
import { RawLocation } from 'vue-router'
import { displayInLocalTimezoneWithDot } from '@/utils/date'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { SavedView } from '@/models/dto/SavedView'
import AssignmentsListAssignedIcon from './AssignmentsListAssignedIcon.vue'
import AssignmentsListActionsIcon from './AssignmentsListActionsIcon.vue'
import { reservationStatusTypes } from '@/data/types'

const CAN_VIEW_CONTACTS = auth.getUserRoleNames.includes(
  ACCESS_SETTINGS_ROLES.CONTACTS
)

@Component({
  components: { CUCollectionTable },
})
export default class AssignmentsUnassignedSidebar extends Vue {
  assignment = assignment
  tableView = reservationClient.tableView

  close(): void {
    sidebar.close()
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '19c89924-293d-4d4d-97d2-3507a553fc37',
      text: 'Actions',
      value: '',
      type: 'slot',
      columnSlotComponent: AssignmentsListActionsIcon,
      sortable: false,
      hidden: false,
      width: 51,
    },
    {
      _t_id: 'dc56abff-4fec-4c0e-8c1c-665111f99f7f',
      text: 'Res. ID',
      value: 'managedId',
      type: 'slot',
      columnSlotComponent: CUDataTableLink,
      linkLocation: (row: Reservation): RawLocation => ({
        name: 'reservations.detail',
        params: { id: String(row.reservationId) },
      }),
      hidden: false,
      filterProp: 'managedId',
      filterable: true,
      filterBySearch: true,
    },
    {
      _t_id: '0405d03e-09a0-441e-99bf-d6f152abad70',
      text: 'Status',
      value: 'reservationStatus',
      hidden: true,
      filterable: true,
      filterInterface: 'multi-select',
      filterDropdownItems: reservationStatusTypes.map((status) => ({
        text: status.label,
        value: status.key,
      })),
      filterProp: 'reservationStatusKey',
      sortable: false,
      filterType: 'eq',
      filterValue: [],
    },
    {
      _t_id: '707f2fed-6cd5-4e85-970b-d0bc96943c6a',
      text: 'Assignment',
      value: 'reservation-assignment-icons',
      type: 'slot',
      columnSlotComponent: AssignmentsListAssignedIcon,
      hidden: false,
      filterable: true,
      sortable: false,
      filterProp: ['assignedDriverPercentage', 'assignedVehiclePercentage'],
      filterInterface: 'select',
      filterDropdownItems: [
        {
          text: 'Needs Assignment',
          value: '100.00',
          filterType: 'neq',
          filterLogic: 'or',
        },
        {
          text: 'Fully Assigned',
          value: '100',
          filterType: 'eq',
          filterLogic: 'and',
        },
      ],
      filterType: 'eq',
      filterValue: -1,
      filterHiddenOnTopBar: false,
    },
    {
      _t_id: '093c6501-4592-4f00-a2e8-08759a40960c',
      text: 'Pickup',
      value: 'pickupDate',
      computedText: (row): string =>
        displayInLocalTimezoneWithDot(
          row.pickupDate,
          false,
          row.firstPickupTimeZone
        ),
      hidden: false,
      filterable: true,
      filterInterface: 'date-range',
      filterProp: 'pickupDate',
      filterValue: [],
    },
    {
      _t_id: '0e6714b9-fd0c-470e-a171-d0e989512548',
      text: 'Final Drop-off',
      value: 'finalDropoffDate',
      computedText: (row): string =>
        displayInLocalTimezoneWithDot(
          row.finalDropoffDate,
          false,
          row.finalDropoffTimeZone
        ),
      hidden: false,
    },
    {
      _t_id: '2d18526c-bb56-4957-9321-4f023b9bb428',
      text: 'Pickup Location',
      value: 'pickupLocation',
      filterBySearch: true,
      filterProp: 'pickupLocation',
      type: 'text',
    },
    {
      _t_id: '518686db-60a8-45a8-9943-86e806c639d0',
      text: 'Company',
      value: 'customerAccountName',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'reservations:open-company',
      hidden: false,
      filterBySearch: true,
      filterable: true,
      filterProp: 'customerAccountName',
    },
    {
      _t_id: 'aaa7d333-ca0d-4cff-820d-b9f42ed1a3ce',
      text: 'Booking Contact',
      value: 'customerName',
      type: CAN_VIEW_CONTACTS ? 'link' : 'text',
      clickEvent: 'reservations:open-contact',
      hidden: false,
      filterable: true,
      filterBySearch: true,
      filterProp: 'customerName',
    },
  ]

  initialSavedViews: SavedView[] = [
    {
      name: 'Default',
      savedViewId: -1,
      tableId: 'unassigned-reservations-tv',
      viewSettings: {
        predefined: true,
        pinned: true,
        orderIndex: 0,
        pageSize: 10,
        columns: [
          {
            _t_id: '19c89924-293d-4d4d-97d2-3507a553fc37',
            label: 'Actions',
            hidden: false,
          },
          {
            _t_id: 'dc56abff-4fec-4c0e-8c1c-665111f99f7f',
            label: 'Res. ID',
            hidden: false,
          },
          {
            _t_id: '0405d03e-09a0-441e-99bf-d6f152abad70',
            label: 'Res. Status',
            hidden: true,
            filterType: 'eq',
            filterValue: ['upcoming', 'started', 'finished', 'hold'],
          },
          {
            _t_id: '707f2fed-6cd5-4e85-970b-d0bc96943c6a',
            label: 'Assignment',
            hidden: false,
            filterLogic: 'or',
            filterType: 'neq',
            filterValue: '100.00',
          },
          {
            _t_id: '093c6501-4592-4f00-a2e8-08759a40960c',
            label: 'Start',
            hidden: false,
          },
          {
            _t_id: '0e6714b9-fd0c-470e-a171-d0e989512548',
            label: 'End',
            hidden: false,
          },
          {
            _t_id: '2d18526c-bb56-4957-9321-4f023b9bb428',
            label: 'Pickup',
            hidden: false,
          },
          {
            _t_id: '518686db-60a8-45a8-9943-86e806c639d0',
            label: 'Company',
            hidden: false,
          },
          {
            _t_id: 'aaa7d333-ca0d-4cff-820d-b9f42ed1a3ce',
            label: 'Booking Contact',
            hidden: false,
          },
        ],
      },
    },
  ]
}
