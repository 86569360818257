
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AssignedBus from '@/assets/images/AssignedBus.vue'
import UnassignedBus from '@/assets/images/UnassignedBus.vue'

@Component({
  components: { AssignedBus, UnassignedBus },
})
export default class AssignmentsListAssignedIcon extends Vue {
  @Prop({ required: true }) row: Reservation

  get areVehiclesFullyAssigned(): boolean {
    return this.row.assignedVehiclePercentage >= 100
  }

  get vehicleAssignmentString(): string {
    const assigned =
      (this.row.assignedVehiclePercentage *
        (this.row.requiredVehicles as number)) /
      100
    return `${Math.round(assigned)}/${this.row.requiredVehicles || 0}`
  }

  get areDriversFullyAssigned(): boolean {
    return this.row.assignedDriverPercentage >= 100
  }

  get driverAssignmentString(): string {
    const assigned =
      (this.row.assignedDriverPercentage * this.row.requiredDrivers) / 100
    return `${Math.round(assigned)}/${this.row.requiredDrivers || 0}`
  }
}
