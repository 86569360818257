import { render, staticRenderFns } from "./CUTimelineLegend2.vue?vue&type=template&id=762e9830&scoped=true"
import script from "./CUTimelineLegend2.vue?vue&type=script&lang=ts"
export * from "./CUTimelineLegend2.vue?vue&type=script&lang=ts"
import style0 from "./CUTimelineLegend2.vue?vue&type=style&index=0&id=762e9830&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "762e9830",
  null
  
)

export default component.exports