
import { Vue, Component } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import assignment from '@/store/modules/assignment'
import AssignmentsHeader from '@/components/AssignmentsHeader.vue'
import AssignmentsVehicleCard from '@/components/AssignmentsVehicleCard.vue'
import AssignmentsDriverCard from '@/components/AssignmentsDriverCard.vue'
import AssignmentsCalendarRow from '@/components/AssignmentsCalendarRow.vue'
import AssignmentsDriverCalendarRow from '@/components/AssignmentsDriverCalendarRow.vue'
import CUTimelineCalendar2 from '@/components/CUTimelineCalendar2.vue'
import sidebar from '@/store/modules/sidebar'
import app from '@/store/modules/app'

@Component({
  components: {
    Main,
    CUTimelineCalendar2,
    AssignmentsHeader,
    AssignmentsVehicleCard,
    AssignmentsDriverCard,
    AssignmentsCalendarRow,
    AssignmentsDriverCalendarRow,
  },
  metaInfo: {
    title: 'Assignments',
  },
})
export default class Assignments extends Vue {
  legendKeys = [
    'Confirmed Reservation',
    'Buffer Time',
    'Draft',
    'Blocked',
    'Schedule Conflict',
  ]
  assignment = assignment
  app = app

  handleCollapseVehicleType(e): void {
    const mustClearSelected = assignment.handleCollapseVehicleType(e)
    if (mustClearSelected) {
      assignment.clearSelected()
      sidebar.close()
      this.$router.replace({ hash: '' })
    }
  }

  mounted(): void {
    if (assignment.preserveDataOnInit) {
      this.$router.replace({
        hash: `${assignment.getSelectedReservation.reservationId}`,
      })
    } else if (this.$route.hash) {
      this.$router.replace({ hash: '' })
    }
    assignment.init()
  }

  beforeDestroy(): void {
    assignment.clearSelected()
    assignment.clearVehiclesAndDrivers()
    assignment.setMultiSwitchViewMode('bus')
  }
}
