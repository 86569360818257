
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AssignedBus from '@/assets/images/AssignedBus.vue'
import UnassignedBus from '@/assets/images/UnassignedBus.vue'
import assignment from '@/store/modules/assignment'
import dayjs from 'dayjs'
import sidebar from '@/store/modules/sidebar'
import AssignmentsSidebar from './AssignmentsSidebar.vue'
import { TimelineScaleOptions } from '@/models/dto/AssignmentsGrid'
import { ReservationStatus } from '@/utils/enum'

@Component({
  components: { AssignedBus, UnassignedBus },
})
export default class AssignmentsListActionsIcon extends Vue {
  @Prop({ required: true }) row: Reservation

  get disabled(): boolean {
    return (
      !this.row.dropoffDate ||
      !this.row.pickupDate ||
      this.row.reservationStatus === ReservationStatus.Cancelled ||
      !this.row.reservationStatus
    )
  }

  async handleClick(): Promise<void> {
    if (this.disabled) {
      return
    }

    sidebar.push({
      component: AssignmentsSidebar,
      persist: true,
      width: 512,
    })
    const diff = dayjs(this.row.dropoffDate).diff(this.row.pickupDate, 'days')
    assignment.setCurrentDate(
      dayjs(this.row.garageDepartureTime || this.row.pickupDate)
    )
    assignment.setScale(
      diff < 1 ? TimelineScaleOptions.DAY : TimelineScaleOptions.WEEK
    )
    await assignment.refresh()
    await assignment.setSelectedIds({
      reservationId: this.row.reservationId,
      vehicleId: null,
      vehicleIdx: 0,
    })
    assignment.setScrollPositionFromReservation()

    if (this.$router.currentRoute.hash !== `#${this.row.reservationId}`) {
      this.$router.replace({ hash: `#${this.row.reservationId}` })
    }
  }
}
