
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  AssignmentsRowBlock,
  AssignmentsVehicleBlock,
  TimelineScaleOptions,
} from '@/models/dto/AssignmentsGrid'
import { getWidthOfTextInFont } from '@/utils/string'
import assignment from '@/store/modules/assignment'
import sidebar from '@/store/modules/sidebar'
import AssignmentsSidebar from './AssignmentsSidebar.vue'
import colors from '@/scss/_colors-export.scss'

@Component
export default class AssignmentsCalendarRow extends Vue {
  @Prop({ required: true }) vehicle: AssignmentsVehicleBlock

  assignment = assignment

  isDragging = null
  // isDraggedOver = false
  canvas = null
  topOffset = 0
  initialMouseY = 0

  get rowHeight(): number {
    return this.vehicle.isVehicleType
      ? 32
      : Math.max(...this.vehicle.posRowBlocks.map((r) => r.top + 1), 1) * 30 + 2
  }

  get selected(): { resId: number; vehicleId: number } {
    return {
      resId: assignment.getSelectedReservation?.reservationId,
      vehicleId: assignment.getSelectedBlock?.vehicleId,
    }
  }

  get bufferTimeWidth(): number {
    switch (assignment.getScale) {
      case TimelineScaleOptions.HALFDAY:
        return (2400 / 24 / 60) * 60
      case TimelineScaleOptions.DAY:
        return (1200 / 24 / 60) * 60
      case TimelineScaleOptions.HALFWEEK:
        return (400 / 24 / 60) * 60
      case TimelineScaleOptions.WEEK:
        return (200 / 24 / 60) * 60
      default:
        return 3
    }
  }

  get assigningCoords(): { start: number; end: number } {
    if (!assignment.getIsAssigning) {
      return null
    }
    return {
      start: assignment.getSelectedReservation.start,
      end: assignment.getSelectedReservation.end,
    }
  }

  getTopOffsetFromBlock(block: AssignmentsRowBlock): string {
    let offset = block.top * 30
    if (!this.isSelected(block)) {
      offset += 2
    }
    if (this.isDragging === block.reservationId) {
      offset += this.topOffset - 64
    }

    return `${offset}px`
  }

  getLeftOffsetFromBlock(block: AssignmentsRowBlock): string {
    let offset = block.start
    if (this.isSelected(block)) {
      offset -= 1
    }
    if (this.isDragging === block.reservationId) {
      offset -= 64
    }

    return `${offset}px`
  }

  blockColor(block: AssignmentsRowBlock): string {
    let base = block.isConfirmed ? colors['secondary'] : colors['bg-gray-5']
    base = block.isTimeoff ? colors['white'] : base
    return !this.selected.resId || this.selected.resId === block.reservationId
      ? base
      : `${base}60`
  }

  isSelected(block: AssignmentsRowBlock): boolean {
    return (
      this.selected.resId === block.reservationId &&
      this.selected.vehicleId === block.vehicleId
    )
  }

  shouldShowAssignmentIndex(block: AssignmentsRowBlock): boolean {
    const validAssignments =
      assignment.getSelectedReservation?.reservationId === block.reservationId
        ? assignment.getSelectedAssignments?.filter((a) => !!a.id)
        : []
    const isBlockWideEnough =
      getWidthOfTextInFont(block.managedId, '12px', '700', this.canvas) <
      block.end - block.start - 30
    return (
      (block.vehicleAssignmentIndex || validAssignments.length > 1) &&
      isBlockWideEnough
    )
  }

  getBlockAssignmentIndex(block: AssignmentsRowBlock): number {
    const matchingSelectedAssignmentIdx =
      assignment.getSelectedReservation?.reservationId === block.reservationId
        ? assignment.getSelectedAssignments?.findIndex(
            (a) => a.id === block.vehicleId
          )
        : -1
    if (matchingSelectedAssignmentIdx !== -1) {
      return matchingSelectedAssignmentIdx + 1
    }
    return block.vehicleAssignmentIndex || 1
  }

  shouldShowTooltip(block: AssignmentsRowBlock): boolean {
    return !this.isDragging && !this.isSelected(block)
  }

  handleSelectBlock(block: AssignmentsRowBlock): void {
    assignment.setSelectedIds({
      reservationId: block.reservationId,
      vehicleId: block.vehicleId,
      vehicleIdx: block.vehicleAssignmentIndex
        ? block.vehicleAssignmentIndex - 1
        : 0,
    })

    sidebar.popAllAndPush({
      component: AssignmentsSidebar,
      persist: true,
      width: 512,
    })

    if (this.$router.currentRoute.hash !== `#${block.reservationId}`) {
      this.$router.replace({ hash: `#${block.reservationId}` })
    }
  }

  handleMouseDown(block: AssignmentsRowBlock, e: any): void {
    e.preventDefault()
    this.isDragging = block.reservationId
    this.initialMouseY = e.clientY
  }

  handleDrag(block: AssignmentsRowBlock, e: any): void {
    if (this.isDragging && this.isSelected(block)) {
      const deltaY = e.clientY - this.initialMouseY
      this.topOffset = deltaY
    }
  }

  handleMouseUp(block: AssignmentsRowBlock): void {
    assignment.dragVehicleBlockTo({ dragDistance: this.topOffset, block })
    this.isDragging = null
    this.topOffset = 0
  }
}
